import './FrameDescription.scss';
/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import {v4 as uuidv4} from 'uuid';
import {useSnackbar} from 'notistack';
import {Client} from '@stomp/stompjs';
import {useAuth0} from '@auth0/auth0-react';
import {Button, IconButton} from '@mui/material';
import {DeleteOutline, OpenInNew} from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import {paths} from 'paths';
import {
    API_FRAME_PROPOSAL_ACTIVITY_TRACKER,
    API_GET_NEXT_FRAME,
    API_GET_NEXT_FRAME_FOR_LEAFLET,
    API_GET_NEXT_REPORTED_FRAME,
    API_LEAFLET_MANAGEMENT_ADVERTISEMENTS,
    API_LEAFLET_MANAGEMENT_LEAFLET_FRAME_DESCRIPTION_DETAILS,
    API_STATUSES
} from 'config/api/constants';
import {sendCurrentFrameDest} from 'config/webSocket/destinations';
import {currentFrameLockPayload} from 'shared/models/websocket.model';
import {frame, framePageNumberOffset, frameTemplate} from 'shared/models/frame.model';
import {productDetailsResponseModel, productSearchResultUI} from 'shared/models/product.model';
import {framePromotionsPayload, promotionSuggestionsForFrame, promotionUI, PROPOSALS_ACTIVITY_TYPE} from 'shared/models/promotion.model';
import {leafletModelWithPageData, leafletSummary, leafletTemplate,} from 'shared/models/leaflet.model';
import {BPCOResponseModel} from 'shared/models/BPCO.model';
import {getCurrencyForCountry} from 'shared/reducers/userProfile';
import {IRootState} from 'shared/reducers';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import {getFromLocalStorage, getObjectFromLocalStorage, storeInLocalStorage} from 'utils/storageUtils';
import {lockLevels, lockSetter, releasePageLockTypes, releasePageLock, renewPageLock} from 'utils/lockUtils';
import {getURLParam} from 'utils/routing';
import {useFormatMessage} from 'utils/translate';
import {connectWebSocketPageLock} from 'utils/websockets';
import {reportIssuesType} from './FrameDescriptionReportDialog';
import Footer from 'components/Footer';
import {LoadingOverlay} from 'components/LoadingOverlay/LoadingOverlay';
import {PaperX} from 'components/PaperX';
import ButtonClose from 'components/Buttons/ButtonClose';
import {FramePreview} from 'components/gfx';
import Promotion from 'components/Promotion';
import LeafletSummary, {buildLeafletSummary} from 'components/LeafletSummary/LeafletSummary';
import CountrySelectorConnected from 'components/Selectors/CountrySelectorConnected/CountrySelectorConnected';
import InfoDialog from 'components/InfoDialog/InfoDialog';
import ToggleTemplates from 'components/Controls/ToggleTemplates';
import PromotionsTable from 'components/PromotionsTable';
import {bulkPromoUpdatePayload} from 'components/PromotionsTable/PromotionsTableEditDrawer';
import {
    createFrameDescriptionPayload,
    makePromotionOfProductSearch
} from 'modules/Advertisement/AdvertisementsOverview/advertisementsOverviewIO';
import {LeafletProgress} from 'modules/Leaflet/PrintMediaOverview';
import FrameValidityDialog from 'modules/Advertisement/FrameValidity/FrameValidityDialog';
import FrameValidity from 'modules/Advertisement/FrameValidity/FrameValidity';
import {ProductSuggestions} from 'modules/Advertisement/FrameDescription/ProductSuggestions';
import MyAdvertisementsDialog from 'modules/Quality/MyAdvertisements/MyAdvertisementsDialog';
import {useSkipFrame} from './frameDescriptionAPI';
import FrameDescriptionReportDialog from './FrameDescriptionReportDialog';
import ProductSearch from './ProductSearch/ProductSearch';
import ProductDrawer, {productDrawerProps} from './ProductDrawer/ProductDrawer';
import BPCODrawer, {BPCODrawerProps} from './BPCODrawer/BPCODrawer';
import {
    addDerivedLeafletValues,
    BPCODetails2productSearchResultUI,
    persistPromotions,
    productDetails2productSearchResultUI,
    restorePromotions,
    transformPromotionsSuggestions
} from './frameDescriptionUtils';
import PromotionsSuggestions from './PromotionsSuggestions/PromotionsSuggestions';
import PromotionDrawer from './PromotionDrawer/PromotionDrawer';
import {zoomTypes} from 'components/gfx/FramePreview/FramePreview';
import {useActivityMonitor, timeIntervalInMin} from 'utils/useActivityMonitor';
import {useTabId} from 'utils/useTabId';

export const arePromotionsValid = (promos:promotionUI[]):boolean => {
    if (promos.length === 0) {
        return false;
    }
    if (promos.find((item) => item.isTemplate)) {
        return false;
    }
    let isPromotionValid = true;
    promos.forEach((item) => {
        if (item.hasError) {
            isPromotionValid = false;
        }
        if (!item.promotionalPrice && !item.regularPrice && !item.absoluteDiscount && !item.relativeDiscount) {
            isPromotionValid = false;
        }
    });
    return isPromotionValid;
};

export const newProductDrawerProps: productDrawerProps = {
    clone: false,
    isNewProduct: true,
    open: false,
    productId: null
};

export const newBPCODrawerProps: BPCODrawerProps = {
    clone: false,
    isNewBPCO: true,
    open: false,
    BPCOId: null
};

export type extendOrOverwritePromotionType = {
    extend: boolean,
    overwriteProductId: string | null
}

const localStorageKeyShowTemplates: string = 'showTemplates_frame_description';
const localStorageKeyViewMode: string = 'viewMode_frame_description';

enum modes {
    DETAILS = 'DETAILS',
    TABLE = 'TABLE'
}

const FrameDescription = ({history}) => {
    const translate = useFormatMessage();
    const { enqueueSnackbar } = useSnackbar();
    const tabId = useTabId();
    const {getAccessTokenSilently} = useAuth0();
    const {activityCount} = useActivityMonitor(timeIntervalInMin);
    const stompClientRef = useRef<Client | null>(null);

    const frameDataApiGet = useApi('get', frameTemplate, {errMsg: 'frameDescription.err'});
    const frameApiPost = useApi('post', {}, {errMsg: 'frameDescription.saveErr', succMsg: 'frameDescription.succ'});
    const leafletFrameDescriptionDetailsApiGet = useApi('get', leafletTemplate);
    const proposalsActivityTrackerPost = useApi('post', {}, {});

    const [returnPath, setReturnPath] = useState<string>(paths.home);
    const [isFixingFrame, setIsFixingFrame] = useState<boolean>(false);
    const [leafletData, setLeafletData] = useState<leafletModelWithPageData>(leafletTemplate);
    const [frameData, setFrameData] = useState<frame>(frameTemplate);
    const [leafletId, setLeafletId] = useState<string>('');
    const [currentTabId, setCurrentTabId] = useState<string>(sessionStorage.getItem('tabId'));
    const [firstTime, setFirstTime] = useState<boolean>(true);
    const [validFrom, setValidFrom] = useState<string>(null);
    const [validTo, setValidTo] = useState<string>(null);
    const [isReportDialogOpen, setIsReportDialogOpen] = useState<boolean>(false);
    const [isValidityDialogOpen, setIsValidityDialogOpen] = useState<boolean>(false);
    const [isMyAdvertisementsDialogOpen, setIsMyAdvertisementsDialogOpen] = useState<boolean>(false);
    const [promotionDrawerOpen, setPromotionDrawerOpen] = useState<boolean>(false);
    const [isInfoDialogOpen, setIsInfoDialogOpen] = useState<boolean>(false);
    const [promotionInDrawerId, setPromotionInDrawerId] = useState<string>('');
    const [productDrawerProperties, setProductDrawerProperties] = useState<productDrawerProps>(newProductDrawerProps);
    const [BPCODrawerProperties, setBPCODrawerProperties] = useState<BPCODrawerProps>(newBPCODrawerProps);
    const [shouldUpdate, setShouldUpdate] = useState<number>(0);
    const [leafletSummary, setLeafletSummary] = useState<leafletSummary>(null);
    const [promotions, setPromotions] = useState<promotionUI[]>([]);
    const [promotionUIId, setPromotionUIId] = useState<string>(null);
    const [countryChangeAllowed, setCountryChangeAllowed] = useState<boolean>(false);
    const [isPromotionsSuggestionsOpen, setIsPromotionsSuggestionsOpen] = useState<boolean>(false);
    const [showTemplates, setShowTemplates] = useState<boolean>(() => {
        const storedShowTemplates = getObjectFromLocalStorage(localStorageKeyShowTemplates);
        return storedShowTemplates !== null ? storedShowTemplates : true;
    });
    const [mode, setMode] = useState<modes>(() => {
        if (getFromLocalStorage(localStorageKeyViewMode) === modes.TABLE) {
            return modes.TABLE;
        }
        return modes.DETAILS;
    });

    const countryMarket = useSelector((state: IRootState) => state.userProfile.countryMarket);

    const getNextFrame = () => {
        currentTabId && frameDataApiGet.call(leafletId ? API_GET_NEXT_FRAME_FOR_LEAFLET(leafletId, currentTabId) : API_GET_NEXT_FRAME(countryMarket.preferredCountry, currentTabId));
        setPromotions([]);
        lockSetter(lockLevels.page);
    }
    const getNextFrameOnCountryChange = () => {
        setLeafletData(leafletTemplate);
        setFrameData(frameTemplate);
        setLeafletSummary(null);
        setValidFrom(null);
        setValidTo(null);
        setLeafletId('');
        setPromotions([]);
        frameDataApiGet.call(API_GET_NEXT_FRAME(countryMarket.preferredCountry, currentTabId));
        lockSetter(lockLevels.page);
    }
    const getFirstFrame = (leafletId: string, frameId: string) => {
        if (leafletId) {
            frameDataApiGet.call(API_GET_NEXT_FRAME_FOR_LEAFLET(leafletId, currentTabId));
        } else {
            frameDataApiGet.call(API_GET_NEXT_REPORTED_FRAME(frameId, currentTabId));
        }
        lockSetter(lockLevels.page);
    }

    const skipApi = useSkipFrame(frameData?.frameId, getNextFrame);

    const handleWebSocketMsgReceive = (msg) => {
        if (msg?.expiredAt) setIsInfoDialogOpen(true);
    };

    const sendCurrentData = (stompClient: Client) => {
        if (stompClient && stompClient.connected) {
            const payload: currentFrameLockPayload = {frameId: frameData?.frameId, tabId: currentTabId};
            stompClient.publish({
                destination: sendCurrentFrameDest,
                body: JSON.stringify(payload),
            });
        }
    };

    useEffect(() => {
        if (!currentTabId) setCurrentTabId(tabId);
    }, [currentTabId]);

    useEffect(() => {
        if (activityCount > 0 && !isInfoDialogOpen) renewPageLock(leafletId, frameData.pageNumber + 1);
      }, [activityCount]);
      
    useEffect(() => {
        const initializeWebSocket = async () => {
            const client: Client = await connectWebSocketPageLock(getAccessTokenSilently, sendCurrentData, handleWebSocketMsgReceive);
            if (client) stompClientRef.current = client;
        };
        if (frameData?.frameId) initializeWebSocket();
        return () => {
            if (stompClientRef.current) stompClientRef.current.deactivate();
        }
    }, [getAccessTokenSilently, frameData?.frameId]);

    useEffect(() => {
        if (isPromotionsSuggestionsOpen) setIsPromotionsSuggestionsOpen(false);
    }, [isPromotionsSuggestionsOpen]);

    useEffect(() => {
        const fromUrl: string = getURLParam('from');
        const fixingFrame: string = getURLParam('fixingFrame');
        if (fromUrl) {
            setReturnPath(fromUrl);
        }
        if (fixingFrame) {
            setIsFixingFrame(true)
        }
        const canChangeCountry = getURLParam('canChangeCountry');
        if (canChangeCountry) {
            setCountryChangeAllowed(true);
        }
    }, []);

    useEffect(() => {
        if (countryChangeAllowed && currentTabId) {
            getNextFrameOnCountryChange();
        }
    }, [countryMarket, currentTabId]);

    useEffect(() => {
        if (leafletFrameDescriptionDetailsApiGet.status === API_STATUSES.IDLE) {
            const response: leafletModelWithPageData = leafletFrameDescriptionDetailsApiGet.data;
            const {headerData, metaData, pageData} = response;
            setLeafletData(response);
            setLeafletSummary(buildLeafletSummary(headerData, metaData, pageData));
        }
    }, [leafletFrameDescriptionDetailsApiGet.status]);

    useEffect(() => {
        if (frameDataApiGet.status === API_STATUSES.IDLE) {
            if (frameDataApiGet.data) {
                const frame: frame = frameDataApiGet.data;
                const {leafletId, pageNumber, validFrom, validTo} = frame;
                leafletFrameDescriptionDetailsApiGet.call(API_LEAFLET_MANAGEMENT_LEAFLET_FRAME_DESCRIPTION_DETAILS(leafletId, pageNumber))
                setLeafletId(leafletId);
                setValidFrom(validFrom);
                setValidTo(validTo);
                const frameData = framePageNumberOffset(frame);
                setFrameData(frameData);
                localStorage.setItem('describeFrameLeafletBrowser_frame', JSON.stringify(frameData));
                localStorage.setItem('describeFrameLeafletBrowser_leafletId', leafletId);
                localStorage.setItem('describeFrameLeafletBrowser_pageNumber', frameData.pageNumber.toString());
                setPromotions(restorePromotions(frame.frameId));
            } else {
                setFrameData(frameTemplate);
                setLeafletData(leafletTemplate);
                enqueueSnackbar(translate({id: 'frameDescription.errNoFrame'}), {variant: 'warning'});
                goHome();
            }
        } else if (frameDataApiGet.status === API_STATUSES.ERROR && frameDataApiGet.errorCode === 409) {
            enqueueSnackbar(`${translate({id: 'page.locked'})}`, {variant: 'error', persist: false});
            goHome();
        }
    }, [frameDataApiGet.status]);

    useEffect(() => {
        if (promotions && promotions.length && frameData.frameId) {
            persistPromotions(frameData.frameId, promotions);
        }
    }, [promotions]);

    const handleSubmit = () => {
        const payload: framePromotionsPayload = createFrameDescriptionPayload(frameData.frameId, validFrom, validTo, promotions);
        frameApiPost.call(API_LEAFLET_MANAGEMENT_ADVERTISEMENTS, payload);
        handleSetSearchInputFocus();
    };

    useEffect(() => {
        if ((frameApiPost.status === API_STATUSES.NEW || frameApiPost.status === API_STATUSES.IDLE) && currentTabId) {
            if (firstTime) {
                const extLeafletId = getURLParam('leafletId');
                const extFrameId = getURLParam('frameId');
                if (extFrameId) {
                    getFirstFrame(null, extFrameId);
                } else if (extLeafletId) {
                    getFirstFrame(extLeafletId, null);
                } else {
                    getNextFrame();
                }
                setFirstTime(false);
            } else {
                if (isFixingFrame) { // fix one specified frame then return
                    goHome();
                } else {
                    getNextFrame();
                    setPromotions([]);
                    setValidFrom(null);
                    setValidTo(null);
                }
            }
            setShouldUpdate(shouldUpdate + 1);
        }
    }, [frameApiPost.status, currentTabId]);

    const handleProductSelect = (selectedProduct: productSearchResultUI) => {
        const newPromotions = [...promotions];

        let derivedSeasonThemes: string[];
        if (leafletData?.pageData?.themeSeasons) {
            derivedSeasonThemes = leafletData?.pageData?.themeSeasons;
        } else derivedSeasonThemes = leafletData?.headerData?.themeSeasons;

        let derivedThemedWeeks: string[];
        if (leafletData?.pageData?.countryThemedWeeks) {
            derivedThemedWeeks = leafletData?.pageData?.countryThemedWeeks;
        } else derivedThemedWeeks = leafletData?.headerData?.countryThemedWeeks;

        let derivedPromotionTypes: string[];
        if (leafletData?.pageData?.promotionTypes) {
            derivedPromotionTypes = leafletData?.pageData?.promotionTypes;
        } else derivedPromotionTypes = leafletData?.headerData?.promotionTypes;

        newPromotions.push(makePromotionOfProductSearch(selectedProduct, derivedSeasonThemes, derivedThemedWeeks, derivedPromotionTypes, getCurrencyForCountry(leafletData?.country)));
        setPromotions(newPromotions);
        enqueueSnackbar(`${translate({id: 'b.productAdded'})}`, {variant: 'success', persist: false});
    };

    const handlePromotionClone = (promotionUIId: string) => {
        const matchedPromotion: promotionUI = promotions.find((item) => item.UIId === promotionUIId);
        const clonedPromotion: promotionUI = _.cloneDeep(matchedPromotion);
        if (clonedPromotion) {
            clonedPromotion.UIId = uuidv4();
            setPromotions([...promotions, clonedPromotion]);
        }
    };

    const handlePromotionCloneMultiple = (ids: string[]) => {
        const matchedPromotions: promotionUI[] = promotions.filter((promotion) => ids.includes(promotion.UIId));
        const clonedPromotions: promotionUI[] = matchedPromotions.map((promotion) => _.cloneDeep(promotion));
        if (clonedPromotions?.length) {
            setPromotions([...promotions].concat(clonedPromotions.map((promotion) => ({...promotion, UIId: uuidv4()}))));
        }
    };

    const handlePromotionUpdate = (key:string, value: any, id: string) => {
        const newPromotions = [...promotions];
        const targetIndex = newPromotions.findIndex((promotion) => promotion.UIId === id);
        newPromotions[targetIndex][key] = value;
        setPromotions(newPromotions)
    };

    const handlePromotionRemoval = (promotionId: string) => {
        const newPromotions = [...promotions].filter((promotion) => promotion.UIId !== promotionId);
        setPromotions(newPromotions);
    };

    const handlePromotionRemovalMultiple = (ids: string[]) => {
        const newPromotions = [...promotions].filter((promotion) => !ids.includes(promotion.UIId));
        setPromotions(newPromotions);
    };

    const handlePromotionRemovalInDrawer = (promotionId: string) => {
        handlePromotionDrawerClose();
        handlePromotionRemoval(promotionId);
    };

    const handlePromotionError = (error: boolean, promotionId: string) => handlePromotionUpdate('hasError', error, promotionId);

    const isBusy = () => frameApiPost.status === API_STATUSES.PENDING || leafletFrameDescriptionDetailsApiGet.status === API_STATUSES.PENDING
        || frameDataApiGet.status === API_STATUSES.PENDING || skipApi.isLoading;

    const goHome = () => {
        if (frameData.frameId) releasePageLock(releasePageLockTypes.frame, frameData.frameId, currentTabId);
        if (returnPath === paths.reportedFramesBrowser) {
            history.push(`${returnPath}?id=${getURLParam('leafletId')}`);
        } else {
            history.push(returnPath);
        }
    };

    const canSave = () => frameData && frameData.frameId && arePromotionsValid(promotions) && !isBusy();

    const skipFrame = () => {
        if (frameData?.frameId) {
            skipApi.mutate();
        }
        setValidTo(null);
        setValidFrom(null);
        setShouldUpdate(shouldUpdate + 1);
    };

    const handleReportFrameSuccess = () => {
        setIsReportDialogOpen(false);
        setValidTo(null);
        setValidFrom(null);
        getNextFrame();
        setShouldUpdate(shouldUpdate + 1);
    };

    const handleDeleteValidity = () => {
        setValidFrom(null);
        setValidTo(null);
    };

    const handleValidityChange = (validity) => {
        setValidFrom(validity.validFrom);
        setValidTo(validity.validTo);
        setIsValidityDialogOpen(false);
    };

    const openNewProductDrawer = () => setProductDrawerProperties({...newProductDrawerProps, open: true});
    const openNewBPCODrawer = () => setBPCODrawerProperties({...newBPCODrawerProps, open: true});

    const openCloneProductDialog = (productId: string, promotionUIId: string) => {
        setPromotionUIId(promotionUIId);
        setProductDrawerProperties({
            clone: true,
            isNewProduct: false,
            open: true,
            productId
        });
    };

    const openCloneBPCODialog = (BPCOId: string, promotionUIId: string) => {
        setPromotionUIId(promotionUIId);
        setBPCODrawerProperties({
            clone: true,
            isNewBPCO: false,
            open: true,
            BPCOId
        });
    };

    const handlePromotionDrawerClose = () => {
        setPromotionDrawerOpen(false);
        setPromotionInDrawerId('');
    };
    const closeProductDrawer = () => setProductDrawerProperties({...newProductDrawerProps, open: false});
    const closeBPCODrawer = () => setBPCODrawerProperties({...newBPCODrawerProps, open: false});

    const handleProductCreated = (createdProduct: productDetailsResponseModel, extendOrOverwrite: extendOrOverwritePromotionType) => {
        if (extendOrOverwrite.extend) {
            handleProductSelect(productDetails2productSearchResultUI(createdProduct));
        } else {
            try {
                const updatePromotion: promotionUI[] = [...promotions];
                const index: number = updatePromotion.findIndex((promotion) => promotion.UIId === promotionUIId);

                const productDetails: productSearchResultUI = productDetails2productSearchResultUI(createdProduct);

                const clonedPromotion:promotionUI = _.cloneDeep(updatePromotion[index]);

                Object.assign(clonedPromotion, productDetails);

                updatePromotion[index] = {
                    ...clonedPromotion,
                    productId: productDetails.id,
                    id: clonedPromotion.UIId
                };
                setPromotions(updatePromotion);
                enqueueSnackbar(`${translate({id: 'b.productAdded'})}`, {variant: 'success', persist: false});
            } catch (e) {
                console.log(e);
                enqueueSnackbar(`${translate({id: 'frameDescription.errCantReuse'})}`, {variant: 'error', persist: false});
            }
        }
        setPromotionUIId(null);
        closeProductDrawer();
    };

    const handleBPCOCreated = (createdBPCO: BPCOResponseModel, extendOrOverwrite: extendOrOverwritePromotionType) => {
        if (extendOrOverwrite.extend) {
            handleProductSelect(BPCODetails2productSearchResultUI(createdBPCO));
        } else {
            const updatePromotion: promotionUI[] = [...promotions];
            const index: number = updatePromotion.findIndex((promotion) => promotion.UIId === promotionUIId);

            const bpcoDetails: productSearchResultUI = BPCODetails2productSearchResultUI(createdBPCO);

            const clonedPromotion:promotionUI = _.cloneDeep(updatePromotion[index]);

            Object.assign(clonedPromotion, bpcoDetails);

            updatePromotion[index] = {
                ...clonedPromotion,
                bpcoId: bpcoDetails.id,
                id: clonedPromotion.UIId
            };
            setPromotions(updatePromotion);
            enqueueSnackbar(`${translate({id: 'b.bpcoAdded'})}`, {variant: 'success', persist: false});
        }
        setPromotionUIId(null);
        closeBPCODrawer();
    };

    const handleProductSuggestionClick = (product: productSearchResultUI) => handleProductSelect(product);
    
    const handlePromotionsSuggestionClick = (promotionsForFrame: promotionSuggestionsForFrame) => {
        proposalsActivityTrackerPost.call(API_FRAME_PROPOSAL_ACTIVITY_TRACKER(frameData.frameId, PROPOSALS_ACTIVITY_TYPE.PROPOSAL_ACCEPTED));
        let derivedSeasonThemes: string[];
        if (leafletData?.pageData?.themeSeasons) {
            derivedSeasonThemes = leafletData?.pageData?.themeSeasons;
        } else derivedSeasonThemes = leafletData?.headerData?.themeSeasons;

        let derivedThemedWeeks: string[];
        if (leafletData?.pageData?.countryThemedWeeks) {
            derivedThemedWeeks = leafletData?.pageData?.countryThemedWeeks;
        } else derivedThemedWeeks = leafletData?.headerData?.countryThemedWeeks;

        let derivedPromotionTypes: string[];
        if (leafletData?.pageData?.promotionTypes) {
            derivedPromotionTypes = leafletData?.pageData?.promotionTypes;
        } else derivedPromotionTypes = leafletData?.headerData?.promotionTypes;

        setPromotions(
            addDerivedLeafletValues(
                transformPromotionsSuggestions(promotionsForFrame), derivedSeasonThemes, derivedThemedWeeks, derivedPromotionTypes
            )
        )
    };

    const handlePromotionsSuggestionsClose = () => {
        handleSetSearchInputFocus();
        proposalsActivityTrackerPost.call(API_FRAME_PROPOSAL_ACTIVITY_TRACKER(frameData.frameId, PROPOSALS_ACTIVITY_TYPE.PROPOSAL_REJECTED));
    }

    const handleTableRowClick2 = (id: string) => {
        setPromotionDrawerOpen(true);
        setPromotionInDrawerId(id);
    };

    const handleSetSearchInputFocus = () => {
        const inputElement: HTMLElement = document.getElementById('productSearchField');
        const focusInputElement = () => inputElement.focus();
        setTimeout(focusInputElement, 0);
    };

    const handleToggleTemplate = (value: boolean) => {
        setShowTemplates(value);
        storeInLocalStorage(localStorageKeyShowTemplates, JSON.stringify(value));
    };

    const handlePasteFromLocalStorage = (ids: string[], data: promotionUI) => {
        const newPromotions: promotionUI[] = [...promotions];
        const { promotionTypes, themeSeasons, countryThemedWeeks, qualitySeals, relativeDiscount, absoluteDiscount,
            mpu, promotionalPrice, regularPrice, giveAwayBundledProducts, validityDates } = data;

        promotions.forEach((promotion, idx) => {
            if (ids.includes(promotion.UIId)) {
                newPromotions[idx] = {
                    ...promotion,
                    promotionTypes, themeSeasons, countryThemedWeeks, qualitySeals, relativeDiscount, absoluteDiscount,
                    mpu, promotionalPrice, regularPrice, giveAwayBundledProducts, validityDates
                }
            }
        });
        setPromotions(newPromotions);
    };

    const toggleModes = () => {
        if (mode === modes.DETAILS) {
            setMode(modes.TABLE);
            storeInLocalStorage(localStorageKeyViewMode, modes.TABLE);
        } else {
            setMode(modes.DETAILS);
            storeInLocalStorage(localStorageKeyViewMode, modes.DETAILS);
        }
    };

    const handleBulkUpdate = (payload: bulkPromoUpdatePayload, promotionUIIids: string[], letItBreak: boolean = false) => {
        promotions.forEach((promotion) => {
            if (promotionUIIids.includes(promotion.UIId)) {
                for (const prop in payload) {
                    if (letItBreak) {
                        handlePromotionUpdate(prop, payload[prop], promotion.UIId);
                    }
                    // do not update if would cause error
                    else if (prop === 'promotionalPrice') {
                        if (payload.promotionalPrice && payload.regularPrice && payload.promotionalPrice < payload.regularPrice) { // payload has both prices and they're both valid (price<regular)
                            handlePromotionUpdate(prop, payload[prop], promotion.UIId);
                        } else if (promotion.regularPrice === null || payload[prop] < promotion.regularPrice) {
                            handlePromotionUpdate(prop, payload[prop], promotion.UIId);
                        }
                    } else if (prop === 'regularPrice') {
                        if (payload.promotionalPrice && payload.regularPrice && payload.promotionalPrice < payload.regularPrice) { // payload has both prices and they're both valid (price<regular)
                            handlePromotionUpdate(prop, payload[prop], promotion.UIId);
                        } else if (promotion.promotionalPrice === null || payload[prop] > promotion.promotionalPrice) {
                            handlePromotionUpdate(prop, payload[prop], promotion.UIId);
                        }
                    } else {
                        handlePromotionUpdate(prop, payload[prop], promotion.UIId);
                    }
                }
            }
        });
    };

    return (
        <div className="viewRoot frameDescriptionRoot">
            <div className="viewport">
                <LoadingOverlay show={isBusy()}/>
                <div className="viewContainer _directionRow">
                    <div className="_directionCol leftCol">
                        <div className="_growRelative">
                            <div className="_fillRelative">
                                <PaperX className="_fullHeight">
                                    <FramePreview frameId={frameData.frameId} customClassName="_aboveDrawer" zoomType={zoomTypes.lens}/>
                                </PaperX>
                            </div>
                        </div>
                        <PaperX className="weldBottom">
                            <LeafletSummary data={leafletSummary} frameValidity={validFrom}/>
                            <div className="_directionRow frameValidity">
                                <FrameValidity frameValidity={{validFrom: validFrom, validTo: validTo}}/>
                                <IconButton className="btn" onClick={() => setIsValidityDialogOpen(true)}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton className="btn" onClick={() => handleDeleteValidity()} disabled={!(validFrom && validTo)}>
                                    <DeleteOutline />
                                </IconButton>
                            </div>
                        </PaperX>
                        <PaperX className="weldTop">
                            {!!leafletId && <LeafletProgress
                                leafletId={leafletId}
                                isApiErr={frameDataApiGet.status === API_STATUSES.ERROR}
                                shouldUpdate={shouldUpdate}
                            />}
                        </PaperX>
                    </div>
                    <div className="advertisementContainer _directionCol">
                        <PaperX>
                            <div className="filtersContainer">
                                <ProductSearch countryId={leafletData.country}
                                               onProductClick={handleProductSelect}
                                               selectedItemsIds={promotions.map((item) => item.productId || item.bpcoId)}
                                               templatesExcluded={!showTemplates}/>
                                <ToggleTemplates value={showTemplates} onChange={(newValue) => handleToggleTemplate(newValue)}/>
                            </div>
                        </PaperX>
                        {mode === modes.DETAILS ?
                            <PaperX className="_fullHeight _fullWidth _scrollY">
                                {promotions.map((item) => <Promotion countryId={leafletData.country}
                                                                     data={item}
                                                                     key={item.UIId}
                                                                     onUpdatePromotion={handlePromotionUpdate}
                                                                     onRemovePromotion={handlePromotionRemoval}
                                                                     onPromotionError={(error, promotionId) => handlePromotionError(error, promotionId)}
                                                                     onCloneProduct={(productId, promotionUIId) => openCloneProductDialog(productId, promotionUIId)}
                                                                     onCloneBPCO={(bpcoId, promotionUIId) => openCloneBPCODialog(bpcoId, promotionUIId)}
                                                                     onClonePromotion={(promotionUIId) => handlePromotionClone(promotionUIId)}
                                />)}
                            </PaperX>
                            :
                            <PaperX className="_fullHeight _fullWidth _fullTable">
                                <PromotionsTable promotions={promotions}
                                                 countryId={leafletData.country}
                                                 onBulkUpdate={(payload, selectedPromotionIds, letItBreak) => handleBulkUpdate(payload, selectedPromotionIds, letItBreak)}
                                                 onCloneBPCO={(bpcoId, promotionUIId) => openCloneBPCODialog(bpcoId, promotionUIId)}
                                                 onCloneProduct={(productId, promotionUIId) => openCloneProductDialog(productId, promotionUIId)}
                                                 onClonePromotion={(promotionUIId) => handlePromotionClone(promotionUIId)}
                                                 onClonePromotionMultiple={(promotionUIId) => handlePromotionCloneMultiple(promotionUIId)}
                                                 onPasteFromLocalStorage={(promotionUIIds, promotion) => handlePasteFromLocalStorage(promotionUIIds, promotion)}
                                                 onRowClick={(promotionId) => handleTableRowClick2(promotionId)}
                                                 onRemovePromotion={handlePromotionRemoval}
                                                 onRemovePromotionMultiple={(promotionUIIds) => handlePromotionRemovalMultiple(promotionUIIds)}
                                />
                            </PaperX>
                        }
                        <PromotionDrawer country={leafletData.country}
                                         data={promotions.find((promotion) => promotion.UIId === promotionInDrawerId)}
                                         onCloneBPCO={(bpcoId, promotionUIId) => openCloneBPCODialog(bpcoId, promotionUIId)}
                                         onCloneProduct={(productId, promotionUIId) => openCloneProductDialog(productId, promotionUIId)}
                                         onClonePromotion={(promotionUIId) => handlePromotionClone(promotionUIId)}
                                         onClose={handlePromotionDrawerClose}
                                         onPromotionError={(error, promotionId) => handlePromotionError(error, promotionId)}
                                         onRemovePromotion={handlePromotionRemovalInDrawer}
                                         onUpdatePromotion={handlePromotionUpdate}
                                         open={promotionDrawerOpen}
                        />
                        <ProductDrawer {...productDrawerProperties}
                                       onClose={closeProductDrawer}
                                       onCreate={(newProduct, extendOrOverwrite) => handleProductCreated(newProduct, extendOrOverwrite)}
                        />
                        <BPCODrawer {...BPCODrawerProperties}
                                    onClose={closeBPCODrawer}
                                    onCreate={(newBPCO, extendOrOverwrite) => handleBPCOCreated(newBPCO, extendOrOverwrite)}
                        />
                    </div>
                </div>
            </div>
            <Footer
                actionsLeft={
                    <>
                        <Button variant="outlined" color="primary" onClick={() => setIsReportDialogOpen(true)} disabled={isFixingFrame}>
                            {translate({id: 'frameDescription.report'})}
                        </Button>
                        <span hidden><Button variant="outlined" color="primary" onClick={skipFrame} disabled>{translate({id: 'frameDescription.skip'})}</Button></span>
                        <div className="countryInputContainer">
                            {countryChangeAllowed && <CountrySelectorConnected/>}
                        </div>
                    </>
                }
                actionsRight={
                    <>
                        <Button variant="outlined" color="primary" onClick={() => setIsPromotionsSuggestionsOpen(true)} disabled={!(leafletData.country && frameData.frameId)}>{translate({id: 'frameDescription.fetchProdSugTitle'})}</Button>
                        <Button variant="outlined" color="primary" onClick={() => toggleModes()}>{translate({id: `${mode === modes.DETAILS ? 'a.summary' : 'a.details'}`})}</Button>
                        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!canSave()}>{translate({id: 'a.submit'})}</Button>
                        <Button variant="outlined" color="primary" tabIndex={-1} onClick={openNewProductDrawer}>{translate({id: 'productSearch.createProduct'})}</Button>
                        <Button variant="outlined" color="primary" tabIndex={-1} onClick={openNewBPCODrawer}>{translate({id: 'productSearch.createBPCO'})}</Button>
                        <Button variant="outlined" color="primary" tabIndex={-1} onClick={() => setIsMyAdvertisementsDialogOpen(true)}>{translate({id: 'nav.myAdvertisements'})}</Button>
                        <Link to={`${paths.describeFrameLeafletBrowser}?id=${leafletId}&pageNumber=${frameData.pageNumber}&frameId=${frameData.frameId}`} target="_blank">
                            <Button variant="outlined" color="primary" endIcon={<OpenInNew/>}>{translate({id: 'nav.showLeaflet'})}</Button>
                        </Link>
                        <ButtonClose onClick={goHome}/>
                    </>
                }
            />
            <FrameDescriptionReportDialog open={isReportDialogOpen}
                                          frameId={frameData?.frameId}
                                          onClose={() => setIsReportDialogOpen(false)}
                                          onSuccess={handleReportFrameSuccess}
                                          reportType={reportIssuesType.frame}
            />
            <FrameValidityDialog open={isValidityDialogOpen}
                                 onClose={() => setIsValidityDialogOpen(false)}
                                 onSave={handleValidityChange}
                                 validity={{validFrom: validFrom, validTo: validTo}}
            />
            <MyAdvertisementsDialog open={isMyAdvertisementsDialogOpen}
                                    onClose={() => setIsMyAdvertisementsDialogOpen(false)}
            />
            <PromotionsSuggestions forceOpen={isPromotionsSuggestionsOpen}
                                   onClick={(promotionsForFrame) => handlePromotionsSuggestionClick(promotionsForFrame)}
                                   onClose={handlePromotionsSuggestionsClose}
                                   countryId={leafletData.country}
                                   frameId={frameData.frameId}
                                   initiallyOpen
            />
            <ProductSuggestions frameId={frameData.frameId} onClick={(product) => handleProductSuggestionClick(product)}/>
            <InfoDialog message={translate({id: 'b.promptLockExpire'})}
                        onConfirm={goHome}
                        open={isInfoDialogOpen}/>
        </div>
    );
};

export default FrameDescription;
